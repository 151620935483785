import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Sobre from '../img/logoCFSP.jpg'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(225, 237, 246);
  height: calc(120vh - 140px); /* altura da tela - (altura do header + altura do footer) */
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(244, 241, 181);
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 500px;
  height: 900px;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 90%;
    height: auto;
  }
`;

const Image = styled.img`
  width: 90%;
  height: 80%;
  /* object-fit: cover; */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @media (max-width: 768px) {
    height: 30%;
  }
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 16px;
  text-align: justify;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 22px;
  }
`;


const AboutUsCard = () => {
  return ( 
    <>   
    <Header/>
    <Container>
      <Card>
        <Image src={Sobre} alt="Sobre nós" />
        <Text>
          <strong>Com uma sede própria consolidada há mais de 15 anos, garantimos um atendimento de qualidade e ágil. Nossa equipe está sempre pronta para atender suas necessidades de urgência ou emergência, colocando à disposição toda a nossa expertise e dedicação. O Chaveiro Falcão tem o profissional qualificado para melhor atendê-lo. Aperfeiçoar sempre é o nosso padrão de qualidade, buscar inovação e atualização é o nosso maior objetivo para melhor servir os nossos clientes. Esperamos atender suas expectativas e necessidades e prestar um excelente atendimento. Consulte nossos serviços. Temos a solução ideal para você ou sua empresa.</strong>
        </Text>
      </Card>
     </Container>
      <Footer/>
      </>
  );
};

export default AboutUsCard;