import React from 'react';
import styled from 'styled-components';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';

const FooterContainer = styled.footer`
  background-color: #333;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const SocialIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SocialIconLink = styled.a`
  color: #fff;
  font-size: 20px;
  margin: 0 10px;
`;

const RightsReserved = styled.p`
  margin-top: 20px;
  text-align: center;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <SocialIconsContainer>
        <SocialIconLink href="https://www.facebook.com/profile.php?id=100008201327101" target="_blank">
          <FaFacebookF />
        </SocialIconLink>
        <SocialIconLink href="#">
          <FaTwitter />
        </SocialIconLink>
        <SocialIconLink href="https://www.instagram.com/chaveirofalcao/?igshid=YmMyMTA2M2Y%3D" target="_blank">
          <FaInstagram />
        </SocialIconLink>
      </SocialIconsContainer>
      <h2>Ligue: (11)96507-0544</h2>
      <RightsReserved>&copy; 2023 Chaveiro Falcão / Todos os direitos reservados.</RightsReserved>
    </FooterContainer>
  );
};

export default Footer;
