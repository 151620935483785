import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Carro from '../img/carro.jpg'
import Carros from '../img/carros.jpg'
import CarroBmw from '../img/carrobmw.jpg'
import Montadoras from '../img/montadoras.png'
import Hilux from '../img/controleHilux.jpg'
import CJeep from '../img/controleJeep.jpg'
import CMercedes from '../img/controleMercedes.jpg'
import CToyota from '../img/controleToyota.jpg'
import CVolks from '../img/controleVolks.jpg'
import Discovery from '../img/discovery.jpg'
import Ignicao from '../img/ignicaoBmw.jpg'
import RT250 from '../img/RT250.jpg'


const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: 600px;
  width: 100%;
  margin-top: 25px;
  border-radius: 10px;
  border: solid 2px #87CEFA;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    height: 300px;
  }

  @media screen and (max-width: 576px) {
    height: 200px;
  }
`;

const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-50%) scale(1.2);
  }
`;

const PrevButton = styled(Button)`
  left: 1rem;
`;

const NextButton = styled(Button)`
  right: 1rem;
`;

const images = [
  Carros,
  Carro,
  CarroBmw,
  Montadoras,
  Hilux,
  Hilux,
  CJeep,
  CMercedes,
  CToyota,
  CVolks,
  Discovery,
  Ignicao,
  RT250,
];

const Carousel = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const intervalRef = useRef();

  const handlePrevClick = () => {
    setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length);
  };

  const handleNextClick = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(intervalRef.current);
  }, [images.length]);

  return (
    <>
    <CarouselContainer>
      <PrevButton onClick={handlePrevClick}>{"<"}</PrevButton>
      <Image src={images[currentImageIndex]} alt="carousel image" />
      <NextButton onClick={handleNextClick}>{">"}</NextButton>
    </CarouselContainer>
    <hr/>
    </>
  );
};

export default Carousel;
