import React from "react";
import styled from "styled-components";
import Header from '../components/Header';
import Footer from '../components/Footer';
import Carousel from "../components/Carousel";
import AbrindoCarro from '../img/abrindo-carro.jpg';
import ChaveCodificada from '../img/chave-codificada.jpg';
import Moto from '../img/moto.jpeg';



const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e1edf6;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f1b5;
  border-radius: 10Px;
  margin-top: 2rem;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

const Card = styled.div`
  width: 300px;
  margin: 0 1rem;
  margin-right: 2rem;
  text-align: center;

  @media (max-width: 768px) {
    margin: 1rem 0;
    width: 45%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  margin: 10px;
  margin-left: 5px;
  /* object-fit: cover; */
  border-radius: 8px;
`;

const CardTitle = styled.h2`
  margin-top: 1rem;
  font-size: 1.5rem;
  text-align: center;
  color: #373535;
`;

const H3 = styled.h3`
  margin-top: 1rem;
  font-size: 1.5rem;
  text-align: center;
  color: #373535;
`;
const H5 = styled.h5`
  margin-top: 1rem;
  font-size: 1.5rem;
  text-align: center;
  color: #373535;
`;

const CardDescription = styled.p`
  margin-top: 0.5rem;
  font-size: 1rem;
  margin-left: 5px;
  color: #555;
`;
const TitleDescription = styled.p`
  margin-top: 0.5rem;
  font-size: 20px;
  text-align: center;
  color: #555;
`;

const WhatsAppButton = styled.a`
  display: block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: #25d366;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #128c7e;
  }
`;


const Home = () => {
  return (
    <>
    <Header/>
    <HomePageContainer>
      
    <TitleDescription><strong>Com uma sede própria consolidada há mais de 15 anos, garantimos um atendimento de qualidade e ágil.<br/> Nossa equipe está sempre pronta para atender suas necessidades de urgência ou emergência,<br/> colocando à disposição toda a nossa expertise e dedicação.</strong></TitleDescription>
      <Carousel />
       <WhatsAppButton
            href="https://api.whatsapp.com/send/?phone=5511965070544&text=Ol%C3%A1%2C+Vi+seu+numero+google+e+quero+um+or%C3%A7amento.&type=phone_number&app_absent=0"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contato pelo WhatsApp
        </WhatsAppButton>
      <TitleDescription><strong>
        O Chaveiro Falcão tem o profissional qualificado para melhor atendê-lo.<br/>
        Aperfeiçoar sempre é o nosso padrão de qualidade,<br/> buscar inovação e atualização é o nosso maior objetivo para melhor servir os nossos clientes.<br/>
        Esperamos atender suas expectativas e necessidades e prestar um excelente atendimento.<br/>
        Consulte nossos serviços. Temos a solução ideal para você ou sua empresa.</strong></TitleDescription>
      <CardContainer>
        <Card>
        <CardTitle>Abertura especializada em veículos</CardTitle>
          <CardImage src={AbrindoCarro} alt="Abertura de veículos" />
          <CardDescription>A chave do seu carro quebrou? Foi esquecida dentro do veículo? Perdeu a chave reserva <strong>Resolvemos</strong> seu problema com a rapidez que você precisa.</CardDescription>
          <WhatsAppButton
            href="https://api.whatsapp.com/send/?phone=5511965070544&text=Ol%C3%A1%2C+Vi+seu+numero+google+e+quero+um+or%C3%A7amento.&type=phone_number&app_absent=0"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contato pelo WhatsApp
          </WhatsAppButton>
        </Card>
        <Card>
          <CardTitle>Chave codificada para veículos</CardTitle>
          <CardImage src={ChaveCodificada} alt="Chave codificada" />          
          <CardDescription>Sua chave codificada parou de funcionar? Ou foi perdida? Nós <strong>confeccionamos </strong>outra pra você. Veículos nascionais ou importados</CardDescription>
          <WhatsAppButton
            href="https://api.whatsapp.com/send/?phone=5511965070544&text=Ol%C3%A1%2C+Vi+seu+numero+google+e+quero+um+or%C3%A7amento.&type=phone_number&app_absent=0"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contato pelo WhatsApp
          </WhatsAppButton>
        </Card>
        <Card>
          <CardTitle>Chave codificada para motos</CardTitle>
          <CardImage src={Moto} alt="Chave codificada para motos" />
          <CardDescription>Perdeu a chave da sua moto? Não tem mais a chave reserva? Ou não funciona mais? <strong>Fazemos </strong>também a chave codificada para sua moto.</CardDescription>
          <WhatsAppButton
            href="https://api.whatsapp.com/send/?phone=5511965070544&text=Ol%C3%A1%2C+Vi+seu+numero+google+e+quero+um+or%C3%A7amento.&type=phone_number&app_absent=0"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contato pelo WhatsApp
          </WhatsAppButton>
        </Card>
      </CardContainer>
      <hr/>
      
    </HomePageContainer>
    <Footer/>
    </>
  );
};

export default Home;

